import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'

const ResetPassword = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.password) {
      setErrorMsg({passwordEmpty: '密码不能为空'})
      return
    }
    if (!formValue.confirmPassword) {
      setErrorMsg({confirmPasswordEmpty: '确认密码不能为空'})
      return
    }
    if (formValue.password.trim() !== formValue.confirmPassword.trim()) {
      setErrorMsg({passwordMismatch: '新密码和确认密码不一致'})
      return
    }

    axios.post('/p/customer_service/account/reset_pwd', { 
      merchant_oid: merchantOid,
      account_oid: props.data.oid,
      password: formValue.password
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          message.success('密码重置成功！')
        } else if (response.errcode === 1) {
          setErrorMsg({passwordError: response.msg})
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title='重置密码'
      destroyOnClose={true}
      width='500px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="重置">

      <p>正在重置
        <span style={{color: '#005dff'}}>《{props.data.name}》</span>
        的密码
      </p>

      <MyInput
        id="password"
        required
        type="password"
        label="新密码"
        placeholder='请输入登陆密码'
        value={formValue.password}
        onChange={setFormValue}
        errorMsg={errorMsg.passwordEmpty || errorMsg.passwordError}
      />

      <MyInput
        id="confirmPassword"
        required
        type="password"
        label="确认密码"
        placeholder='确认登陆密码'
        value={formValue.confirmPassword}
        onChange={setFormValue}
        errorMsg={errorMsg.confirmPasswordEmpty || errorMsg.passwordMismatch}
      />
    </Modal>
  )
}
export default ResetPassword
