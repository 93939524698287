import React from 'react'
import { Modal } from 'antd'

const PlayVideo = (props) => {
  const { filename } = props.data


  return (
    <Modal 
      title="播放视频"
      width='1000px'
      open={props.isOpen}
      onCancel={props.isCancel}
      footer=''
    >

      <video controls autoPlay style={{ width: '100%' }}>
        <source src={filename} type="video/mp4" />
      </video>
    </Modal>
  )
}

export default PlayVideo
