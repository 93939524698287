import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import AddGroup from './add'
import EditGroup from './edit'
import DeleteGroup from './delete'

export default function CustomerServiceGroup() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [groupList, setGroupList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddGroupVisible, setIsAddGroupVisible] = useState(false)
  const [isEditGroupVisible, setIsEditGroupVisible] = useState(false)
  const [isDeleteGroupVisible, setIsDeleteGroupVisible] = useState(false)

  const getGroupList = () => {
    axios.post('/p/customer_service/group/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setGroupList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getGroupList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const editGroup = (oid) => {
    setIsEditGroupVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteGroup = (oid, name) => {
    setIsDeleteGroupVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">客服分组</div>
        </div>

        <div className='public-main-style'>
          <div className='public-main-style1'>
            <div className='public-main-style1-add' onClick={() => { setIsAddGroupVisible(true) }}>
              <div className='public-main-style1-add-icon'>
                <MyIcon name='jiahao' />
              </div>
              <div className='public-main-style1-add-title'>创建新的分组</div>
            </div>

            {groupList.map(group => (
              <div className='public-main-style1-content' key={group.oid} onClick={() => { editGroup(group.oid) }}>
                <div>
                  <div className='public-main-style1-content-title'>
                    <div className='public-main-style1-content-name'>{group.name}</div>
                    <div className='public-main-style1-content-delete' 
                      onClick={(event) => {event.stopPropagation(); deleteGroup(group.oid, group.name)}}>
                      <MyIcon name='huishouzhan' />
                    </div>
                  </div>
                  <div className='public-main-style1-content-description'>
                    {group.descrption ? group.descrption : '暂无分组介绍'}
                  </div>
                </div>
                <div className='public-main-style1-content-other'>
                  <div className='public-main-style1-content-other-left'>
                    内含 {group.accout_number} 名客服
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isAddGroupVisible && <AddGroup
        getGroupList={getGroupList} 
        isOpen={isAddGroupVisible} 
        isCancel={() => { setIsAddGroupVisible(false) }} 
      />}

      {isEditGroupVisible && <EditGroup
        data={propsData}
        getGroupList={getGroupList}
        isOpen={isEditGroupVisible}
        isCancel={() => { setIsEditGroupVisible(false) }}
      />}

      {isDeleteGroupVisible && <DeleteGroup
        data={propsData}
        getGroupList={getGroupList}
        isOpen={isDeleteGroupVisible}
        isCancel={() => { setIsDeleteGroupVisible(false) }}
      />}
    </div>
  )
}
