import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ContentFilter from '../../../components/filter/contentFilter'
import AddVideo from './add'
import EditVideo from './edit'
import DeleteVideo from './delete'
import PlayVideo from './play'
import MyIcon from '../../../components/general/icon'

export default function Video() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [videoList, setVideoList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [isNextPage, setIsNextPage] = useState(0)
  const [isAddVideoVisible, setIsAddVideoVisible] = useState(false)
  const [isEditVideoVisible, setIsEditVideoVisible] = useState(false)
  const [isDeleteVideoVisible, setIsDeleteVideoVisible] = useState(false)
  const [isPlayVideoVisible, setIsPlayVideoVisible] = useState(false)

  const initialConditions = {
    column_oid: '',
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getVideoList = () => {
    setCurrentPage(1)
    setIsNextPage(0)
    axios.post('/p/content/video/list/get', { 
      merchant_oid: merchantOid,
      column_oid: filterValue.column_oid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: 1, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setVideoList(response.data.videos)
          if (response.data.total_page > 1) {
            setIsNextPage(1)
          }
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getVideoList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue])

  const loadNextPage = () => {
    setCurrentPage(page => page + 1)
    axios.post('/p/info/video/list/get', { 
      merchant_oid: merchantOid,
      column_oid: filterValue.column_oid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: currentPage + 1, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          const newData = response.data.videos
          if (response.data.total_page <= currentPage + 1) {
            setIsNextPage(2)
          }
          setVideoList(prevData => {
            const newDataIds = newData.map(item => item.oid)
            const filteredData = prevData.filter(item => !newDataIds.includes(item.oid))
            return [...filteredData, ...newData]
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const editVideo = (oid) => {
    setIsEditVideoVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteVideo = (oid, title) => {
    setIsDeleteVideoVisible(true)
    setPropsData({ oid: oid, title: title})
  }

  const playPlay = (filename) => {
    setIsPlayVideoVisible(true)
    setPropsData({ filename: filename})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">视频列表</div>
          <ContentFilter
            addName='视频'
            category={3}
            filterValue={filterValue} 
            setFilterValue={setFilterValue} 
            isAddVisible={() => { setIsAddVideoVisible(true) }}  
          />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th style={{width: '300px'}}>
                    <div className='public-main-style2-title'>视频</div>
                  </th>
                  <th>所属栏目</th>
                  <th>排序</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {videoList && (videoList.length > 0 ? (videoList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editVideo(item.oid) }}>
                    <td style={{width: '300px'}}>
                      <div className='public-main-style2-tuwen'>
                        <img 
                          onClick={(event) => {event.stopPropagation(); playPlay(item.filename)}}
                          style={{width: '100px', height: '80px', margin: '10px 15px 10px 0'}} 
                          src={item.thumbnail} alt="" 
                        />
                        <span>{item.title}</span>
                      </div>
                    </td>
                    <td>{item.column_name}</td>
                    <td>{item.sort}</td>
                    <td style={{width: '50px'}}>
                      <div className='public-main-style2-operation-box'>
                        <div className='public-main-style2-operation' 
                          onClick={(event) => {event.stopPropagation(); deleteVideo(item.oid, item.title)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan='4'><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                {isNextPage === 1 ? (
                  <tr className='public-main-style2-tfootNext'>
                    <td colSpan='4'>
                      <div className='public-main-style2-nextPage' onClick={loadNextPage}>下一页</div>
                    </td>
                  </tr>
                ) : ( isNextPage === 2 && 
                  <tr className='public-main-style2-tfootOver'>
                    <td colSpan='4'>已加载全部</td>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      {isAddVideoVisible && <AddVideo
        getVideoList={getVideoList} 
        isOpen={isAddVideoVisible} 
        isCancel={() => { setIsAddVideoVisible(false) }} 
      />}

      {isEditVideoVisible && <EditVideo
        data={propsData}
        getVideoList={getVideoList} 
        isOpen={isEditVideoVisible} 
        isCancel={() => { setIsEditVideoVisible(false) }} 
      />}

      {isDeleteVideoVisible && <DeleteVideo
        data={propsData}
        getVideoList={getVideoList} 
        isOpen={isDeleteVideoVisible} 
        isCancel={() => { setIsDeleteVideoVisible(false) }} 
      />}

      {isPlayVideoVisible && <PlayVideo
        data={propsData}
        isOpen={isPlayVideoVisible} 
        isCancel={() => { setIsPlayVideoVisible(false) }} 
      />}
    </div>
  )
}
