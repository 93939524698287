import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyImageUpload from '../../../components/forms/imageUpload'
import MyRadio from '../../../components/forms/radio'
import MyTextArea from '../../../components/forms/textArea'
import MySwitch from '../../../components/forms/switch'

const EditMiniappSlide = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/deco/slide/detail/get', {
      merchant_oid: merchantOid,
      slide_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          const { mode, path, ...rest } = response.data;
          if (mode === 4) {
            setFormValue({ ...rest, mode, path, finderUserName: path });
          } else if (mode === 5 && path) {
            const [finderUserName, feedId] = path.split('&');
            setFormValue({ ...rest, mode, path, finderUserName, feedId });
          } else if (mode === 7 && path) {
            const [appId, appPath] = path.split('&');
            setFormValue({ ...rest, mode, path, appId, appPath });
          } else {
            setFormValue(response.data);
          }
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.thumbnail) {
      setErrorMsg({thumbnailEmpty: '图片不能为空'})
      return false
    }

    let path
    if (formValue.mode === 4) {
      path = formValue.finderUserName
    } else if (formValue.mode === 5) {
      path = formValue.finderUserName + '&' + formValue.feedId
    } else if (formValue.mode === 7) {
      path = formValue.appId + '&' + (formValue.appPath || '')
    } else {
      path = formValue.path
    }

    axios.post('/p/deco/slide/edit', { 
      merchant_oid: merchantOid,
      slide_oid: formValue.oid,
      title: formValue.title,
      thumbnail: formValue.thumbnail,
      mode: formValue.mode,
      is_hide: formValue.is_hide,
      path: path,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getSlideList()
          message.success('轮播图修改成功！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title='编辑轮播图'
      destroyOnClose={true}
      width='800px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyImageUpload 
        id="thumbnail"
        required
        label='图片'
        value={formValue.thumbnail}
        setValue={setFormValue}
        errorMsg={errorMsg.thumbnailEmpty}
      />

      <MyInput
        id="title"
        autoFocus
        maxLength={64}
        label='标题'
        placeholder='请输入轮播图的标题'
        value={formValue.title}
        onChange={setFormValue}
      />

      <MyRadio
        id='mode'
        label='跳转页面'
        options={[
          {
            label: '二级页面',
            value: 1,
          },
          {
            label: '底部导航',
            value: 3,
          },
          {
            label: '网页链接',
            value: 2,
          },
          {
            label: '视频号主页',
            value: 4,
          },
          {
            label: '视频号视频',
            value: 5,
          },
          {
            label: '第三方小程序',
            value: 7,
          },
          {
            label: '暂未开放',
            value: 6,
          },
          {
            label: '不跳转',
            value: 0,
          }
        ]}
        value={formValue.mode}
        onChange={setFormValue}
      />

      {[1, 2, 3].includes(formValue.mode) && <MyTextArea 
        id="path"
        minRows={2}
        placeholder='请输入完整路径'
        value={formValue.path}
        onChange={setFormValue}
      />}

      {[4, 5].includes(formValue.mode) && <MyInput
        id="finderUserName"
        maxLength={128}
        label='视频号id'
        placeholder='以“sph”开头的id，可在视频号助手获取'
        value={formValue.finderUserName}
        onChange={setFormValue}
      />}

      {formValue.mode === 5 && <MyInput
        id="feedId"
        maxLength={128}
        label='视频id'
        placeholder='请输入视频 feedId'
        value={formValue.feedId}
        onChange={setFormValue}
      />}

      {formValue.mode === 7 && <MyInput
        id="appId"
        maxLength={128}
        label='小程序appid'
        placeholder='请输入小程序 appId'
        value={formValue.appId}
        onChange={setFormValue}
      />}

      {formValue.mode === 7 && <MyInput
        id="appPath"
        maxLength={128}
        label='小程序path'
        placeholder='请输入小程序路径，选填，默认首页'
        value={formValue.appPath}
        onChange={setFormValue}
      />}

      <MySwitch 
        id='is_hide'
        label='是否隐藏'
        checked={formValue.is_hide}
        onChange={setFormValue}
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}

export default EditMiniappSlide
