import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyTextArea from '../../../components/forms/textArea'

const EditGroup = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/customer_service/group/detail/get', {
      merchant_oid: merchantOid,
      group_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '名称不能为空'})
      return false
    }

    axios.post('/p/customer_service/group/edit', { 
      merchant_oid: merchantOid, 
      group_oid: formValue.oid, 
      name: formValue.name,  
      sort: Number(formValue.sort),
      descrption: formValue.descrption
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getGroupList()
          message.success('修改成功！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title='编辑分组'
      destroyOnClose={true}
      width='780px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyInput
        id="name"
        required
        autoFocus
        maxLength={64}
        label='名称'
        placeholder='请输入分组的名称'
        value={formValue.name}
        onChange={setFormValue}
        errorMsg={errorMsg.nameEmpty}
      />

      <MyTextArea 
        id="descrption"
        label='描述'
        minRows={3}
        placeholder='请输入分组的描述'
        value={formValue.descrption}
        onChange={setFormValue}
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}
export default EditGroup
